var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "title-box flex-align-center" },
        [
          _c(
            "BlockTitle",
            {
              staticClass: "msg-title",
              attrs: { title: "打卡时段", "is-bold": true },
            },
            [
              _c("template", { slot: "left" }, [
                _c("span", { staticClass: "msg-span" }, [
                  _vm._v(
                    "请按时间轴从上往下设置上班时间段、加班时段，注意时段间不能交叉"
                  ),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "a-timeline",
        [
          _c("a-timeline-item", [
            _c(
              "div",
              { staticClass: "time-setting" },
              [
                _c("span", [_vm._v("一天开始时间")]),
                _c("CbTimePicker", {
                  attrs: { options: _vm.timeSetUp },
                  on: { change: _vm.getDayEndTime },
                  model: {
                    value: _vm.form.dayStartTime,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "dayStartTime", $$v)
                    },
                    expression: "form.dayStartTime",
                  },
                }),
                _c("span"),
              ],
              1
            ),
          ]),
          _vm.form.shiftType === "B1"
            ? _c(
                "div",
                _vm._l(_vm.form.shiftPunchRuleList, function (item, index) {
                  return _c("a-timeline-item", { key: index }, [
                    _c("div", { staticClass: "time-card" }, [
                      _vm.form.shiftType === "B1"
                        ? _c(
                            "div",
                            { staticClass: "time-title flex-just-between" },
                            [
                              _c("div", [
                                _c("span", [
                                  _vm._v(_vm._s("第 " + (index + 1) + "段")),
                                ]),
                              ]),
                              index
                                ? _c(
                                    "svg",
                                    {
                                      staticClass: "iconpark-icon",
                                      on: {
                                        click: function ($event) {
                                          return _vm.del(index)
                                        },
                                      },
                                    },
                                    [
                                      _c("use", {
                                        attrs: { href: "#delete-one" },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "time-content" },
                        [
                          _c(
                            "a-row",
                            [
                              _c("a-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "title" }, [
                                  _vm._v("出勤时段"),
                                ]),
                              ]),
                              _c("a-col", { attrs: { span: 10 } }, [
                                _c("span", { staticClass: "title" }, [
                                  _vm._v("有效打卡时段"),
                                ]),
                              ]),
                              _c("a-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "title" }, [
                                  _vm._v("规则设定"),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "a-row",
                            {
                              staticClass: "time-box",
                              attrs: { type: "flex", justify: "space-between" },
                            },
                            [
                              _c(
                                "a-col",
                                { attrs: { span: 7 } },
                                [
                                  _c(
                                    "a-form-model-item",
                                    {
                                      staticClass: "timepicker",
                                      attrs: { label: "上班时间" },
                                    },
                                    [
                                      _c("CbTimePicker", {
                                        attrs: {
                                          "default-value": "00:00",
                                          options: _vm.timeSetUp,
                                        },
                                        on: { change: _vm.change },
                                        model: {
                                          value: item.startTime,
                                          callback: function ($$v) {
                                            _vm.$set(item, "startTime", $$v)
                                          },
                                          expression: "item.startTime",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-form-model-item",
                                    {
                                      staticClass: "timepicker",
                                      attrs: { label: "下班时间" },
                                    },
                                    [
                                      _c("CbTimePicker", {
                                        attrs: {
                                          "parent-disabled": !item.startTime,
                                          options: _vm.timeSetUp,
                                        },
                                        on: { change: _vm.change },
                                        model: {
                                          value: item.endTime,
                                          callback: function ($$v) {
                                            _vm.$set(item, "endTime", $$v)
                                          },
                                          expression: "item.endTime",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { span: 10 } },
                                [
                                  _c(
                                    "a-form-model-item",
                                    {
                                      staticClass: "time-bottom",
                                      attrs: { label: "上班打卡时段" },
                                    },
                                    [
                                      _c("CbTimePicker", {
                                        attrs: {
                                          "parent-disabled": !item.endTime,
                                          options: _vm.timeSetUp,
                                        },
                                        on: { change: _vm.change },
                                        model: {
                                          value: item.punchInStartTime,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "punchInStartTime",
                                              $$v
                                            )
                                          },
                                          expression: "item.punchInStartTime",
                                        },
                                      }),
                                      _c("CbTimePicker", {
                                        attrs: {
                                          "parent-disabled":
                                            !item.punchInStartTime,
                                          options: _vm.timeSetUp,
                                        },
                                        on: { change: _vm.change },
                                        model: {
                                          value: item.punchInEndTime,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "punchInEndTime",
                                              $$v
                                            )
                                          },
                                          expression: "item.punchInEndTime",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-form-model-item",
                                    {
                                      staticClass: "time-bottom",
                                      attrs: { label: "下班打卡时段" },
                                    },
                                    [
                                      _c("CbTimePicker", {
                                        attrs: {
                                          "parent-disabled":
                                            !item.punchInEndTime,
                                          options: _vm.timeSetUp,
                                        },
                                        on: { change: _vm.change },
                                        model: {
                                          value: item.punchOutStartTime,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "punchOutStartTime",
                                              $$v
                                            )
                                          },
                                          expression: "item.punchOutStartTime",
                                        },
                                      }),
                                      _c("CbTimePicker", {
                                        attrs: {
                                          "parent-disabled":
                                            !item.punchOutStartTime,
                                          options: _vm.timeSetUp,
                                        },
                                        on: { change: _vm.change },
                                        model: {
                                          value: item.punchOutEndTime,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "punchOutEndTime",
                                              $$v
                                            )
                                          },
                                          expression: "item.punchOutEndTime",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { span: 7 } },
                                [
                                  _c(
                                    "a-form-model-item",
                                    {
                                      staticClass: "timepicker",
                                      attrs: { label: "打卡次数" },
                                    },
                                    [
                                      _c(
                                        "a-select",
                                        {
                                          attrs: { "allow-clear": "" },
                                          model: {
                                            value: item.punchTimesRuleType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item,
                                                "punchTimesRuleType",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.punchTimesRuleType",
                                          },
                                        },
                                        _vm._l(
                                          _vm.clockInTimesList,
                                          function (v) {
                                            return _c(
                                              "a-select-option",
                                              {
                                                key: v.value,
                                                attrs: {
                                                  value: v.value,
                                                  title: v.label,
                                                },
                                              },
                                              [_vm._v(_vm._s(v.label))]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-form-model-item",
                                    { attrs: { label: "异常判断" } },
                                    [
                                      _c(
                                        "a-checkbox",
                                        {
                                          model: {
                                            value: item.checkLateInFlag,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item,
                                                "checkLateInFlag",
                                                $$v
                                              )
                                            },
                                            expression: "item.checkLateInFlag",
                                          },
                                        },
                                        [_vm._v("需要记迟到")]
                                      ),
                                      _c(
                                        "a-checkbox",
                                        {
                                          model: {
                                            value: item.checkEarlyOutFlag,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item,
                                                "checkEarlyOutFlag",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.checkEarlyOutFlag",
                                          },
                                        },
                                        [_vm._v("需要记早退")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "time-bottom" },
                            [
                              _c(
                                "a-checkbox",
                                {
                                  on: { change: _vm.change },
                                  model: {
                                    value: item.breakFlag,
                                    callback: function ($$v) {
                                      _vm.$set(item, "breakFlag", $$v)
                                    },
                                    expression: "item.breakFlag",
                                  },
                                },
                                [_vm._v("休息时间")]
                              ),
                              _c("CbTimePicker", {
                                attrs: {
                                  "parent-disabled":
                                    !item.endTime || !item.breakFlag,
                                  "start-disabled": item.startTime,
                                  "end-disabled": item.endTime,
                                  options: _vm.timeSetUp,
                                },
                                on: { change: _vm.change },
                                model: {
                                  value: item.breakStartTime,
                                  callback: function ($$v) {
                                    _vm.$set(item, "breakStartTime", $$v)
                                  },
                                  expression: "item.breakStartTime",
                                },
                              }),
                              _c("a-icon", {
                                staticClass: "swap-right",
                                attrs: { type: "swap-right" },
                              }),
                              _c("CbTimePicker", {
                                attrs: {
                                  "parent-disabled":
                                    !item.breakStartTime || !item.breakFlag,
                                  "start-disabled": item.breakStartTime,
                                  "end-disabled": item.endTime,
                                  options: _vm.timeSetUp,
                                },
                                on: { change: _vm.change },
                                model: {
                                  value: item.breakEndTime,
                                  callback: function ($$v) {
                                    _vm.$set(item, "breakEndTime", $$v)
                                  },
                                  expression: "item.breakEndTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                }),
                1
              )
            : _vm._e(),
          _vm.form.shiftType === "B1"
            ? _c("a-timeline-item", [
                _c(
                  "div",
                  { staticClass: "time-add" },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: { type: "link", prefix: "add-one" },
                        on: {
                          click: function ($event) {
                            return _vm.form.shiftPunchRuleList.push({
                              breakFlag: false,
                            })
                          },
                        },
                      },
                      [_vm._v(" 添加上下班时间 ")]
                    ),
                    _c("div", [
                      _vm.form.shiftHours
                        ? _c("span", [
                            _vm._v("合计工作时长："),
                            _c("span", { staticClass: "time-hour" }, [
                              _vm._v(_vm._s(_vm.form.shiftHours)),
                            ]),
                            _vm._v("小时/天；"),
                          ])
                        : _vm._e(),
                      _vm.form.shiftOvertimeHours
                        ? _c(
                            "span",
                            { staticStyle: { "margin-right": "16px" } },
                            [
                              _vm._v("最长加班总时长（不含提前上班加班）："),
                              _c("span", { staticClass: "time-hour" }, [
                                _vm._v(_vm._s(_vm.form.shiftOvertimeHours)),
                              ]),
                              _vm._v("小时/天"),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c("a-timeline-item", [
            _c(
              "div",
              { staticClass: "time-setting" },
              [
                _c("span", [_vm._v(" 一天结束时间")]),
                _c("CbTimePicker", {
                  attrs: { "parent-disabled": true, options: _vm.timeSetUp },
                  model: {
                    value: _vm.form.dayEndTime,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "dayEndTime", $$v)
                    },
                    expression: "form.dayEndTime",
                  },
                }),
                _c("span", [_vm._v("次日")]),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }